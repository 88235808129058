import React from 'react';
import { graphql } from 'gatsby';
import { join } from 'path';

import ProductGrid from '../components/ProductGrid';
import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';

function CategoryPage({
  data: {
    markdownRemark,
    allMarkdownRemark
  },
}) {
  if (!markdownRemark) return null;
  const category = markdownRemark.frontmatter

  return (
    <React.Fragment>
      <SEO pageTitle={category.name} />
      <Breadcrumbs
        paths={[
          { name: category.name, to: join('/collections', category.url, '/') },
        ]}
      />
      <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
        {category.name}
      </h1>
      <hr className="border-b border-gainsboro w-10" />

      <ProductGrid categoryUrl={category.url} products={allMarkdownRemark.edges} />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query CategoryQuery($url: String!) {
    markdownRemark(frontmatter: {url: {eq: $url}}) {
      frontmatter {
        name
        url
        description
        image {
          childImageSharp {
            fluid(maxWidth: 560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {categories: {eq: $url}}}
      sort: { fields: [frontmatter___sort], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            name
            url_key
            status
            images {
              childImageSharp {
                fluid(maxWidth: 560) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            variations {
              price
              formattedPrice
            }
          }
        }
      }
    }
  }
`;

export default CategoryPage;
