import React from 'react';

import Product from './Product';

function ProductGrid({ categoryUrl, products }) {
  if (!products) return null;

  return (
    <div className="flex flex-wrap -mx-6">
      {products.filter(p => p.node.frontmatter.status === 'published').map(({node: { frontmatter}}) => (
        <Product
          key={frontmatter.url_key}
          {...frontmatter}
          categoryUrl={categoryUrl}
        ></Product>
      ))}
    </div>
  );
}

export default ProductGrid;
