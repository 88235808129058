import React, { useMemo } from 'react';
import Img from 'gatsby-image';
import { join } from 'path';

import LocaleLink from './LocaleLink';

function Product({ id, name, url_key, variations, images, categoryUrl = '' }) {
  const [{ formattedPrice }] = variations;
  const minPrice = useMemo(
    () =>
      variations.reduce(function (prev, curr) {
        return prev.price < curr.price ? prev : curr;
      })?.formattedPrice,
    [variations]
  );
  const maxPrice = useMemo(
    () =>
      variations.reduce(function (prev, curr) {
        return prev.price > curr.price ? prev : curr;
      })?.formattedPrice,
    [variations]
  );
  const productImage = images[0];
  const categoryPrefix = categoryUrl ? join('/collections', categoryUrl) : '';

  return (
    <article key={id} className="p-6 w-full md:w-1/2 lg:w-1/3">
      <LocaleLink
        to={join(categoryPrefix, url_key, '/')}
        className="group no-underline w-full h-full flex"
      >
        <div className="bg-gainsboro rounded-lg cursor-pointer w-full overflow-hidden relative px-3 py-6 md:px-6">
          <Img
            fluid={productImage.childImageSharp.fluid}
            alt={name}
            title={name}
            className="w-full aspect-[3/2]"
          />

          <div className="pt-3 md:pt-6 text-center">
            <p className="text-slategray font-bold text-lg group-hover:text-primary mb-1">
              {name}
            </p>
            {minPrice === maxPrice ? (
              <p className="text-lightgray text-sm">{formattedPrice}</p>
            ) : (
              <p className="text-lightgray text-sm">{minPrice} - {maxPrice}</p>
            )}
          </div>
        </div>
      </LocaleLink>
    </article>
  );
}

export default Product;
