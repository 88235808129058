import React from 'react';

import LocaleLink from './LocaleLink';

function Breadcrumbs({ paths }) {
  return (
    <div className="py-4">
      <LocaleLink
        className="text-lightgray hover:text-slategray font-medium"
        to="/"
      >
        Sākums
      </LocaleLink>
      {paths.map((path, index) => (
        <React.Fragment key={index}>
          <span
            className="text-lightgray mx-2"
            style={{ pointerEvents: 'none' }}
          >
            {'>'}
          </span>
          <LocaleLink
            className="text-lightgray hover:text-slategray font-medium"
            to={path.to}
          >
            {path.name}
          </LocaleLink>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Breadcrumbs;
